import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ItemDeal = ({ link = "/", image = null, title = "", cta = "", description = null, headerClass = '' }) => {
    const the_image = getImage(image)

    return (
        <article className="item-deal mb-8 md:mb-4">
            <a
                href={link}
                rel="nofollow noopener sponsored"
                className="overflow-hidden rounded-xl block text-center relative bg-zone-1 h-full transform duration-300 shadow-lg"
            >
                <p className={`${headerClass} text-white font-playfair text-2xl font-bold flex min-h-170 p-1 sm:p-2 items-center justify-center`}>
                    {title} <br />
                    {cta}
                </p>
                <div className="absolute w-full -mt-8 sm:-mt-11">
                    <div className="bg-zone-13 my-4 mx-8 rounded-lg p-2 transform duration-300 image-wrapper">
                        <GatsbyImage
                            image={the_image}
                            alt={title}
                        />
                    </div>
                </div>
                <div className="content-deal" dangerouslySetInnerHTML={{ __html: description }} />
            </a>
        </article >
    )
}

export default ItemDeal
